import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Drawer, Flex, Input, message, Modal, Row, Select, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useMutation} from "@apollo/client";
import {DELETE_CUSTOMER_DISCOUNT, EDIT_CUSTOMER_DISCOUNT} from "../../apollo/apollo-queries";
import CustomerDiscountForm from "./CustomerDiscountForm";
import {NotificationsContext} from "../common/NotificationsContext";


const CustomerDiscountTable = ({selectedUser, setSelectedUser, discounts, refetch, loading, customers}) => {
    const {notifications} = useContext(NotificationsContext)
    const [deleteDiscount, {
        loading: deleteDiscountLoading
    }] = useMutation(DELETE_CUSTOMER_DISCOUNT, {
        errorPolicy: "all", onCompleted: async (res) => {
            await refetch();
            message.info("Sconto eliminato con successo.")
        }, onError: async (error) => {
            await notifications.error("Errore nel server: " + error.message)
        }
    });
    const [editDiscount, {
        loading: editDiscountLoading
    }] = useMutation(EDIT_CUSTOMER_DISCOUNT, {
        errorPolicy: "all",
        onCompleted: async (data) => {
            await refetch();
        },
        onError: async (error) => {
            await notifications.error("Errore nel server: " + error.message)
        }
    });


    const [isModalOpen, setModalOpen] = useState(false);
    const [discount, setDiscount] = useState('');
    const [discountTBE, setDiscountTBE] = useState();
    const [visible, setVisible] = useState(false);

    const columns = [
        {
            title: "Cliente",
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: "Categoria",
            dataIndex: 'target',
            key: 'target',
            filters: [{
                text: 'accessori',
                value: 'accessori',
            }, {
                text: 'cerchi',
                value: 'cerchi',
            }, {
                text: 'lubrificanti',
                value: 'lubrificanti',
            }, {
                text: 'ricambi',
                value: 'ricambi',
            }, {
                text: 'vernici',
                value: 'vernici',
            }],
            onFilter: (value, record) => record.target === value,
        },
        {
            title: "Brand",
            dataIndex: 'brand',
            key: 'brand',
            filters: (selectedUser && discounts) ? Object.keys(
                discounts?.getCustomerDiscounts.filter(item => item.user === selectedUser)
                    .map(({brand}) => brand)
                    .reduce((acc, item) => {
                        acc[item] = '';
                        return acc;
                    }, {}))
                .map(key => ({
                    text: key,
                    value: key,
                })) : [],
            onFilter: (value, record) => record.brand === value,

        },
        {
            title: "Sconto",
            dataIndex: 'discount',
            key: 'discount',
            render: text => `${text} %`
        },
        {
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => {
                        setModalOpen(true);
                        setDiscountTBE(parseInt(record.key));
                        setDiscount(record.discount);
                    }}>
                        <EditOutlined/>
                    </Button>
                    <Button
                        type="link"
                        danger
                        loading={deleteDiscountLoading || editDiscountLoading}
                        onClick={async () => {
                            // eslint-disable-next-line no-restricted-globals
                            if (confirm("Sicuro di eliminare lo sconto?")) {
                                await deleteDiscount({
                                    variables: {
                                        discount: {
                                            ...discounts.getCustomerDiscounts[parseInt(record.key)]
                                        }
                                    }
                                })
                            }
                        }}>
                        <DeleteOutlined/>
                    </Button>
                </Space>
            ),
        },
    ];

    return <Flex vertical={true} gap={"middle"}>
        <Row justify={"space-between"}>
            <Select
                showSearch
                style={{width: '250px'}}
                placeholder={'Seleziona utente'}
                options={customers.map(
                    (customer, idx) => ({value: customer, label: customer, key: idx})
                )}
                onChange={(value) => {
                    setSelectedUser(value);
                    sessionStorage.setItem('selectedUser', value);
                }}
                value={selectedUser}
            ></Select>

            <Button
                icon={<PlusOutlined/>}
                type={"primary"}
                onClick={() => {
                    setVisible(true)
                }}
            > Aggiungi sconto</Button>
        </Row>
        <Modal title={`Modifica sconto cliente ${selectedUser}`}
               open={isModalOpen}
               onOk={async () => {
                   await editDiscount({
                       variables: {
                           discount: {...discounts.getCustomerDiscounts[discountTBE], discount: parseFloat(discount)}
                       }
                   })
               }}
               onCancel={() => {
                   setDiscount('');
                   setModalOpen(false);
                   setDiscountTBE(undefined);
               }}
               okButtonProps={{
                   disabled: discount === ''
               }}
        >
            <Input
                ref={discounts}
                placeholder={`€`}
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
            />
        </Modal>
        <Table
            dataSource={discounts?.getCustomerDiscounts?.filter(item => item.user === selectedUser)
                .map((user, idx) => {
                    return {key: idx.toString(), ...user}
                })}
            columns={columns}
            bordered={true}
            size={"small"}
            pagination={{pageSize: 10}}
            loading={loading}/>

        <Drawer
            title={"Aggiungi sconto"}
            size={'default'}
            onClose={() => {
                setVisible(false);
            }}
            open={visible}
        >
            <CustomerDiscountForm discounts={discounts?.getCustomerDiscounts} refetch={refetch}></CustomerDiscountForm>
        </Drawer>
    </Flex>;
};

export default CustomerDiscountTable;
