import CustomerDiscountTable from "./CustomerDiscountTable";
import {useLazyQuery, useQuery} from "@apollo/client";
import {
    CUSTOMERS_MAILS_QUERY,
    CUSTOMERS_QUERY,
    GET_CUSTOMER_DISCOUNT,
    GET_CUSTOMER_DISCOUNT_AND_CUSTOMERS
} from "../../apollo/apollo-queries";
import {useContext, useEffect, useState} from "react";
import {NotificationsContext} from "../common/NotificationsContext";

export const CustomerDiscount = () => {
    const {notifications} = useContext(NotificationsContext)
    const {
        data: customers, loading
    } = useQuery(CUSTOMERS_MAILS_QUERY, {
        errorPolicy: "all",
        onCompleted: data => {
            setCustomerMails(data.customers.map(c => c.email))
        }
    });

    const [fetchDiscountAndCustomers, {
        data: customerDiscounts, refetch: refetchCustomerDiscounts, loading: customerDiscountsLoading
    }] = useLazyQuery(GET_CUSTOMER_DISCOUNT, {
        errorPolicy: "all",
        onError: error => notifications.error('Impossibile caricare i clienti.')
    });

    const [customerMails, setCustomerMails] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        setSelectedUser(sessionStorage.getItem('selectedUser'));
        return sessionStorage.removeItem('selectedUser');
    }, []);

    useEffect(() => {
        if (selectedUser)
            fetchDiscountAndCustomers({variables: {customer: selectedUser}});
    }, [fetchDiscountAndCustomers, selectedUser]);

    return <CustomerDiscountTable
        customers={customerMails}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        discounts={customerDiscounts}
        refetch={refetchCustomerDiscounts}
        loading={loading || customerDiscountsLoading}
    />
}
